import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  console.log(' post.frontmatter :>> ', 
  post);

  const baseurl = 'https://bizhightech.com'
  const url = baseurl + post.fields.slug
  const imgUrl = post.frontmatter.featuredImg.childImageSharp.fluid.src

  const image = getImage(post.frontmatter.featuredImg)
  console.log('imgUrl :>> ', imgUrl);
  return (
    <Layout >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={imgUrl}
        isBlog={true}
      />
      <div className="page-container services">
        <section id="app-dev">
          <div className="container">
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >
              <div>
                <div className="post-tag">
                  <h1 className="color-1" itemProp="headline">{post.frontmatter.title}</h1>
                  <em className="color-0 post-tag">{post.frontmatter.tag}</em>
                  <p className="post-description color-1">{post.frontmatter.description}</p>
                </div>
                <GatsbyImage image={image} alt={post.frontmatter.featuredCaption} />
                <div className="post-img-caption">
                  <p className="color-1 center">{post.frontmatter.featuredCaption}</p>
                </div>
              </div>
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
                className="color-dark"
              />
              {
                post.frontmatter.author && <>
                  <hr className="color-dark-bg-tr" />
                  <footer>
                    <h4 className="signature color-1"><em>Par: </em>{post.frontmatter.author}</h4>
                  </footer>
                </> 
              }
            </article>
          </div>
        </section>
      </div>

      {/* <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields{
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        tag
        featuredImg{
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [AUTO, AVIF, WEBP])
            fluid{
              src
            }
          }
        }
        featuredCaption
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
